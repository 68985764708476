<template>
  <page>
    <div class="offlineConfig">
      <span
        >允许消费者通过线下转账的方式完成付款，关闭时消费者仅支持线上扫码、工行卡转账付款。</span
      >
      <el-switch
        style="margin-left: 10px"
        v-model="payConfig"
        :active-value="true"
        :inactive-value="false"
        @change="switchHandler"
      >
      </el-switch>
    </div>
    <div v-if="importContent">
      <span>线下联系电话：{{ offlinePhone || '未录入' }}</span>
      <el-button
        style="margin: 20px 0 0 20px"
        type="text"
        @click="setupVisible = true"
        >设置</el-button
      >
    </div>

    <SetupModal
      v-if="setupVisible"
      @setUp="
        val => {
          offlinePhone = val
        }
      "
      :offlinePhone="offlinePhone"
      :visible.sync="setupVisible"
    />
  </page>
</template>

<script>
import SetupModal from './components/SetupModal.vue'
export default {
  data() {
    return {
      offlinePhone: '',
      payConfig: false,
      setupVisible: false,
      importContent: false
    }
  },
  created() {
    this.queryPayConfig()
  },
  components: {
    SetupModal
  },
  methods: {
    async queryPayConfig() {
      try {
        const res = await this.$api.offlineConfig.queryPayConfig()

        if (res.payConfig) {
          this.payConfig = res.payConfig.supportPayOffline == 1 ? true : false

          if (this.payConfig) {
            this.importContent = true
          }

          this.offlinePhone = res.payConfig.offlinePhone
        }
      } catch (e) {
        console.log(e)
      }
    },
    switchHandler(val) {
      const txt = val
        ? '启用后，消费者将可以通过该方式完成付款，确认要开启该收款方式？'
        : '停用后，消费者将无法通过该方式完成付款，确认要停用使用该收款方式？'

      this.$confirm(`${txt}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          try {
            await this.$api.offlineConfig.operate({
              payConfig: val ? 1 : 0
            })
            this.$message.success('操作成功！')
          } catch (e) {
            this.payConfig = !val
            console.log(e)
          }
        })
        .catch(() => {
          this.payConfig = !val
        })
        .finally(() => {
          this.importContent = this.payConfig
        })
    }
  }
}
</script>

<style scoped lang="scss">
.offlineConfig {
  display: flex;
}
</style>
