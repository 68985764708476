<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="设置线下收款信息"
    :visible.sync="show"
    width="500px"
  >
    <div class="content">
      <el-form
        label-width="100px"
        :model="form"
        size="mini"
        :rules="rules"
        ref="ruleForm"
      >
        <div class="row">
          <el-form-item label="联系电话" prop="offlinePhone">
            <el-input
              type="number"
              v-model="form.offlinePhone"
              placeholder="请输入联系电话"
            />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" :loading="loading" @click="submit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['offlinePhone'],
  data() {
    const _this = this
    return {
      rules: {
        offlinePhone: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) return callback(new Error('请输入联系电话'))

              if (!_this.$utils.isPhone(value)) {
                return callback(new Error('手机号不正确'))
              }
              callback()
            }
          }
        ]
      },
      form: {
        offlinePhone: ''
      },
      loading: false
    }
  },
  created() {
    this.form.offlinePhone = this.offlinePhone || ''
  },
  methods: {
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.loading = true
          try {
            await this.$api.offlineConfig.setupPhone({
              offlinePhone: this.form.offlinePhone
            })
            this.$message.success('设置成功')

            this.show = false
            this.$emit('setUp', this.form.offlinePhone)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.row {
  display: flex;
  .left,
  .right {
    flex: 1;
  }
  .right {
    margin-left: 40px;
  }
}
</style>
